export const FirebaseConfig = {
	"projectId": "point-to-point-express-llc",
	"appId": "1:118264622282:web:aaef201c64ac5765f61833",
	"databaseURL": "https://point-to-point-express-llc-default-rtdb.firebaseio.com",
	"storageBucket": "point-to-point-express-llc.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDObF7uuxWwZSxqkYKYhPSGbVxHf7PVzh8",
	"authDomain": "point-to-point-express-llc.firebaseapp.com",
	"messagingSenderId": "118264622282",
	"measurementId": "G-VRVKVNDRS2"
};